import React, { useEffect, useState } from 'react';
import { match, Link } from 'react-router-dom';
import ArticleInfo from '../../interfaces/ArticleInfo';
import ReactMarkdown from 'react-markdown';
import ArticleMetas from '../../datas/ArticleMetas';

const fetchArticleBody = async (id: string) => {
  const url = `/articles/${id}/body.md`;
  console.log(url);
  const res = await fetch(url);
  let text = await res.text();
  // 画像パスだけ差し替え
  text = text.replace(/\.\/(\w+)/g, `/articles/${id}/$1`);
  console.log(text);
  return text;
};

interface Props {
  match: match,
}
export default function ArticleBody(props: Props) {
  // TODO: ここはきれいに型定義したらany型にせず安全にとれるはず。
  let matchParams: any = props.match.params;
  const id: string = matchParams.articleId;

  // 記事データの状態管理とローディング状態の管理
  const [article, setArticle] = useState({} as ArticleInfo);
  const [nowloding, setLoading] = useState(true);

  // 副作用
  useEffect(() => {
    // 記事データのfetch処理
    let unmounted = false;
    (async () => {
      // ローディング状態にして記事データのfetch
      setLoading(true);
      if (!unmounted) {
        // 記事データの状態更新、ローディング解除
        const body = await fetchArticleBody(id);
        const meta = ArticleMetas.find(m => m.id == id);
        const ai = { title: meta?.title ?? "", body: body, description: meta?.description ?? "" };
        setArticle(ai);
        updateMetaTags(ai);
        setLoading(false);

        // ハイライトと数式は記事表示後に実行しなければならない
        (window as any).hightlightCallback();
        (window as any).mathjaxCallback();
      }
    })();
    // 解除用
    const cleanup = () => { unmounted = true; };
    return cleanup;
  }, [id, setArticle, setLoading]);

  // 記事ID, 記事状態, ローディング状態に依存して副作用を反映
  // 
  return (<article>
    {nowloding ? <h2>loding ...</h2> :
      <>
        <h1>{article.title}</h1>
        <ReactMarkdown source={article.body} escapeHtml={false} />
      </>
    }
  </article>);
}

/**
 * 記事情報をもとにメタタグを更新する
 * @param ai 記事情報
 */
function updateMetaTags(ai: ArticleInfo) {
  // OGP用のメタタグ
  // <!--meta-->
  // <meta property="og:title" content="programium.net" />
  // <meta property="og:type" content="article" />
  // <meta property="og:description" content="C# で競技プログラミングを頑張るためのサイトです。" />
  // <meta property="og:url" content="https://programium.net" />
  // <meta property="og:image" content="https://programium.net/site-image.png" />
  // <meta property="og:site_name" content="programium.net - C# で競技プログラミングに挑戦するためのサイト" />
  // <meta property="og:locale" content="ja_JP" />
  // <meta name="twitter:card" content="summary">
  // <meta name="twitter:site" content="@mntm12345">
  // <!--meta-->
  const headerItems: HTMLCollection = document.head.children;
  for (let i = 0; i < headerItems.length; i++) {
    let item = headerItems[i];
    document.title = `${ai.title} - C# で競技プログラミングに挑戦するためのサイト`;
    if (item.nodeName == 'META') {
      const property = item.getAttribute('property');
      if (property == 'og:description') {
        item.setAttribute(property, ai.description);
      } else if (property == 'og:url') {
        item.setAttribute(property, window.location.href);
      } else if (property == 'og:ite_name') {
        item.setAttribute(property, `${ai.title} - C# で競技プログラミングに挑戦するためのサイト`);
      }
    }
  }
}
