import React from "react";
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Collapse from '@material-ui/core/Collapse';
import LoopIcon from '@material-ui/icons/Loop';
import FlashOnIcon from '@material-ui/icons/FlashOn';
import NotesIcon from '@material-ui/icons/Notes';
import SearchIcon from '@material-ui/icons/Search';
import TimelineIcon from '@material-ui/icons/Timeline';
import PostAddIcon from '@material-ui/icons/PostAdd';
import FunctionsIcon from '@material-ui/icons/Functions';
import { useHistory } from 'react-router';
import ArticleMetas from '../../datas/ArticleMetas';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      maxWidth: 360,
      backgroundColor: theme.palette.background.paper,
    },
    nested: {
      paddingLeft: theme.spacing(4),
    },
  }),
);
export default function SideMenuList() {
  return <>
    {Zentansaku()}
    {BasicAlgorithm()}
    {/* {CumulativeSum()}
    {BinarySearch()}
    {Math()}
    {Graph()}
    {Dp()}
    {DataStructure()} */}
  </>;
}

interface MenuItem {
  key: string;
  subtitle: string;
  subsubtitle?: string | undefined;
}
function Zentansaku() {
  return MyList("全探索・全列挙", "全探索・全列挙", <LoopIcon></LoopIcon>);
}
function BasicAlgorithm() {
  return MyList("基本アルゴリズム", "基本アルゴリズム", <FlashOnIcon></FlashOnIcon>);
}

// function CumulativeSum() {
//   return MyList("累積和", [
//     { key: "cumulative-sum", subtitle: "累積和入門" },
//     { key: "shakutori", subtitle: "尺取り法" },
//     // { key: "imos  ", subtitle: "imos法" },
//     // { key: "cumulative-sum-2d", subtitle: "2次元累積和" },
//     // { key: "cumulative-xxx", subtitle: "累積XXX" },
//   ], <PostAddIcon></PostAddIcon>);
// }

// function BinarySearch() {
//   return MyList("二分探索", [
//     { key: "binary-search", subtitle: "二分探索入門", subsubtitle: "LowerBound, UpperBound" },
//   ], <SearchIcon></SearchIcon>);
// }

// function Math() {
//   return MyList("数学", [
//     { key: "euclid", subtitle: "ユークリッドの互除法", subsubtitle: "最小公倍数と最大公約数" },
//     { key: "eratosthenes", subtitle: "エラトステネスの篩", subsubtitle: "素数列挙と素因数分解" },
//     { key: "divisor", subtitle: "約数", subsubtitle: "約数列挙、約数の和、約数の個数" },
//     // { key: "ncr", subtitle: "組み合わせの計算と逆元"},
//   ], <FunctionsIcon></FunctionsIcon>);
// }

// function Graph() {
//   return MyList("グラフ", [
//     { key: "graph", subtitle: "グラフの種類と性質" },
//     { key: "dfs", subtitle: "深さ優先探索(DFS)" },
//     { key: "bfs", subtitle: "幅優先探索(BFS)" },
//   ], <TimelineIcon></TimelineIcon>);
// }

// function Dp() {
//   return MyList("動的計画法", [
//     { key: "knapsack", subtitle: "DP入門", subsubtitle: "ナップサック問題" },
//     { key: "memo-rec", subtitle: "メモ化再帰" },
//     // { key: "k-dp", subtitle: "桁DP" },
//     // { key: "range-dp", subtitle: "区間DP" },
//     // { key: "tree-dp", subtitle: "木DP、全方位木DP" },
//   ], <NotesIcon></NotesIcon>);
// }

// function DataStructure() {
//   return MyList("データ構造", [
//     { key: "array-list", subtitle: "配列とリスト" },
//     { key: "queue-stack", subtitle: "QueueとStack" },
//     { key: "linked-list-dequeu", subtitle: "連結リストとDeque" },
//     { key: "set-map", subtitle: "SetとMap", subsubtitle: "HashSetとDictionary" },
//     { key: "priority-queue", subtitle: "優先度付きキュー", subsubtitle: "最大値最小値を取り出す" },
//     { key: "union-find", subtitle: "Union-Find木", subsubtitle: "素集合を管理する" },
//   ], <NotesIcon></NotesIcon>);
// }

function MyList(title: string, category: string, iconComponent: JSX.Element) {
  const menuItems: MenuItem[] = [];
  ArticleMetas.forEach((v, _) => {
    if (v.category === category) menuItems.push({ key: v.id, subtitle: v.title, subsubtitle: v.subtitle });
  });

  const classes = useStyles();
  const [open, setOpen] = React.useState(true);
  const handleOpen = () => setOpen(!open);
  const history = useHistory();
  const handleToPage = (id: string) => history.push(`/article/${id}`);
  const dense = true;
  return <><List>
    <ListItem button onClick={handleOpen} dense={dense}>
      <ListItemIcon>
        {iconComponent}
      </ListItemIcon>
      <ListItemText primary={title} />
      {open ? <ExpandLess /> : <ExpandMore />}
    </ListItem>
    <Collapse in={open} timeout="auto" unmountOnExit>
      <List component="div" disablePadding>
        {menuItems.map((item) => (
          <ListItem button key={item.key} className={classes.nested} dense={dense} onClick={() => handleToPage(item.key)}>
            <ListItemText primary={item.subtitle} secondary={item.subsubtitle} />
          </ListItem>
        ))}
      </List>
    </Collapse>
  </List>
    <Divider />
  </>
}