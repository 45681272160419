import React from "react";
import { Link } from "react-router-dom";

export default function Footer() {
  return <footer style={{ textAlign: 'center' }}>
    <div>
      <Link to="/">C# で競技プログラミングに挑戦するためのサイト</Link>
      &nbsp;-&nbsp;
      <small>© 2020 TM999TM</small>
    </div>
    
  </footer>;
}