import React from 'react';
import ArticleBody from '../../containers/organisms/ArticleBody';
import { Route, Switch, Link } from 'react-router-dom';
import clsx from 'clsx';
import { makeStyles, Theme, createStyles, createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { useMediaQuery } from '@material-ui/core';
import SideMenuList from '../organisms/SideMenuList';
import TopPageContents from '../organisms/TopPageContents';
import NotFound from '../organisms/NotFound';
import Footer from '../organisms/Footer';

const drawerWidth = 280;
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    appBar: {
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    appBarShift: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    hide: {
      display: 'none',
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
    },
    drawerPaper: {
      width: drawerWidth,
    },
    drawerHeader: {
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
      justifyContent: 'flex-end',
    },
    content: {
      flexGrow: 1,
      width: '100%',
      padding: theme.spacing(1, 2),
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      marginLeft: -drawerWidth,
    },
    contentShift: {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    },
  }),
);
const lighttheme = createMuiTheme({
  palette: {
    type: 'light',
    primary: { main: '#333', },
    secondary: { main: '#ab003c', },
  },
});
const darktheme = createMuiTheme({
  palette: {
    type: 'dark',
    primary: { main: '#333', },
    secondary: { main: '#ab003c', },
    background: {
      paper: '#212121',
      default: '#212121',
    },
    text: {
      primary: '#EFEFEF',
    }
  },
});

export default function MainContentsWithSideBar() {
  // TODO: PC-Mobile判定(もっといい方法を探す)
  const pc = window.outerWidth > 600;
  const classes = useStyles();

  // ドロア開閉用
  const [open, setOpen] = React.useState(pc);
  const handleDrawerOpen = () => { setOpen(true); };
  const handleDrawerClose = () => { setOpen(false); };

  // TODO: テーマを変えるための設定(現状はdark固定にしている)
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  const theme = React.useMemo(
    () => prefersDarkMode ? darktheme : lighttheme,
    [prefersDarkMode],
  );

  return <ThemeProvider theme={theme}>
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, open && classes.hide)}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap>
            <Link to="/" style={{ color: "inherit", textDecoration: "none" }}>C# で競技プログラミングに挑戦するためのサイト</Link>
          </Typography>
        </Toolbar>
      </AppBar>
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="left"
        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        </div>
        <Divider />
        {/* 無条件に表示させるルーターは、propsに渡すため */}
        <Route path='/' component={SideMenuList}></Route>
      </Drawer>
      <div className={clsx(classes.content, {
        [classes.contentShift]: open,
        mainContents: true
      })}>
        <main>
          {/* ヘッダ調整用の高さ */}
          <div className={classes.drawerHeader} />
          <Switch>
            <Route path='/' exact component={TopPageContents} />
            <Route path='/article/:articleId' exact component={ArticleBody} />
            <Route component={NotFound} />
          </Switch>
        </main>
        <Footer></Footer>
      </div>
    </div>
  </ThemeProvider >;
}
