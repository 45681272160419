import SampleMeta from "./sample";
import HelloMeta from "./hello";

interface Meta {
  id: string,
  category: string,
  tags: string[],
  title: string,
  subtitle: string,
  description: string,
  createdAt: Date,
  updatedAt: Date,
  order: number,
}

let order = 1;

// const ArticleMetas: Map<string, Meta> = new Map();
// ArticleMetas.set({
//   id: "loop",
//   category: "全探索・全列挙",
//   tags: ["全探索"],
//   title: "ループを使った全探索",
//   subtitle: "",
//   createdAt: new Date(2020, 5, 24),
//   updatedAt: new Date(2020, 5, 24),
//   order: order++,
// });
const ArticleMetas: Meta[] = [
  // 全探索
  {
    id: "loop",
    category: "全探索・全列挙",
    tags: ["全探索"],
    title: "ループを使った全探索",
    subtitle: "",
    description: "ループを使った最も基本的な全探索・全列挙アルゴリズムについての解説記事です。",
    createdAt: new Date(2020, 5, 24),
    updatedAt: new Date(2020, 5, 24),
    order: order++,
  }, {
    id: "bits-search",
    category: "全探索・全列挙",
    tags: ["ビット全探索", "全探索"],
    title: "ビット全探索",
    subtitle: "",
    description: "ビット全探索についての解説記事です。",
    createdAt: new Date(2020, 5, 24),
    updatedAt: new Date(2020, 5, 24),
    order: order++,
  }, {
    id: "permutation",
    category: "全探索・全列挙",
    tags: ["順列", "全探索"],
    title: "順列全探索",
    subtitle: "",
    description: "順列を列挙するアルゴリズムを解説します。C++の next_permutation を自前実装してみます。",
    createdAt: new Date(2020, 5, 24),
    updatedAt: new Date(2020, 5, 24),
    order: order++,
  },
  // 基本アルゴリズム
  {
    id: "cumulative-sum",
    category: "基本アルゴリズム",
    tags: ["累積和", "1次元累積和", "imos法"],
    title: "累積和とimos法",
    subtitle: "",
    description: "累積和についてのまとめ記事です。imos法についても解説しています。",
    createdAt: new Date(2020, 5, 24),
    updatedAt: new Date(2020, 5, 24),
    order: order++,
  }, 
];


export default ArticleMetas;