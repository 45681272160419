import React from 'react';
import './App.css';
import Article from './components/pages/Article';
import {
  BrowserRouter,
} from 'react-router-dom';

function App() {
  return (
    <BrowserRouter>
      <Article></Article>
    </BrowserRouter>
  );
}

export default App;
