import React from "react";

export default function TopPageContents() {
  document.title = 'C# で競技プログラミングに挑戦するためのサイト';
  return <article>
    <h1>このサイトについて</h1>
    <p>
      様々なアルゴリズムやデータ構造をわかりやすく解説し、実際にプログラミングでどのように実装されるかをまとめます。
      コードの記述は主にC#で行います。
    </p>
    <p>
      また、AtCoderやAOJの問題を使って、アルゴリズムを実際に使って問題を解いてみます。
    </p>
    <p>
      サイトの足りない機能や記事はおいおい追加される予定です。
    </p>
  </article>;
}